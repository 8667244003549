<template>
    <div class="mask fixed left-0 right-0 top-0 bottom-0 bg-$black-color6 flex flex-col items-center justify-center px-[20px]">
      <div id="mycanvas" class="w-full flex xl:w-120 xl:min-h-155 <xl:max-w-[480px] <xl:min-h-[600px] overflow-hidden bg-$theme-color rounded-[26PX]">
        <div class="w-full h-full flex flex-col p-[10PX]">
          <!-- 头 -->
          <div class="title flex items-center <xl:p-[16px] xl:p-4">
            <img
              :src="store.state.merchant.logo || homeImg"
              crossOrigin='anonymous'
              class="logo border border-[#FFF] <xl:w-[66px] <xl:h-[66px] <xl:rounded-[26px] xl:w-22.5 xl:h-22.5 xl:rounded-[26PX]"
              alt="logo"
            />
            <div class="name text-$color-fff flex-1 <xl:pl-[14px] pl-4 hyphens-auto leading-normal text-ellipsis2">
              <p class="text-left <xl:text-[18px] xl:text-lg">{{ store.state.merchant.name }}</p>
            </div>
          </div>
          <!-- 主体 -->
          <div class="w-full flex-1 flex justify-between flex-col p-[10PX] py-[20PX] bg-$color-fff rounded-b-[26PX]">
            <template v-if="type === 'qr'">
              <div class="text-center text-$black-color font-medium xl:text-3xl <xl:text-[30px]">
                <span>₹</span>
                <span class="xl:text-5xl <xl:leading-[80px]" :class="String(store.state.merchant.amount).length > 8 ? '<xl:text-[36px]' : '<xl:text-[68px]'">
                  {{ store.state.merchant.amount }}
                </span>
              </div>
              <div class="text-center flex-1">
                <canvas class="inline-block xl:my-4 <xl:my-[10px]" id="qrCanvas"></canvas>
                <p class="w-full inline-block <xl:text-[16px] xl:text-base">
                  Scan the QR using any UPI app on your
                  <br/>
                  phone like BHIM, PhonePe,Google Pay etc.
                </p>
              </div>
            </template>
            <template v-if="type === 'result'">
              <header :class="resultInfo.iconColor">
                <div class="result-box flex flex-row justify-center items-center xl:text-2xl <xl:text-[30px] font-medium">
                    <img class="inline-block mr-[4PX] xl:w-12.5 xl:h-12 <xl:w-[50px] <xl:h-[48px]" :src="resultInfo.iconUrl" alt="" />
                    <div>{{ resultInfo.iconText }}</div>
                </div>
              </header>
              <div class="flex-auto mx-[20PX] xl:mt-8 <xl:mt-[32px]">
                <van-cell-group :border="false">
                  <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment Amount" :value="'₹' + (resultInfo.totalAmount || 0)" />
                  <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment Method" v-if="resultInfo.paymentMethod" :value="resultInfo.paymentMethod" />
                  <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment Time" v-if="resultInfo.txnTime" :value="resultInfo.txnTime" />
                  <van-cell title-class="cell-title" value-class="cell-value truncate" :border="false" title="Payment ID" v-if="resultInfo.transactionId" :value="resultInfo.transactionId" />
                  <van-cell title-class="cell-title" value-class="cell-value truncate" :border="false" title="Merchant Name" v-if="resultInfo.merchantName" :value="resultInfo.merchantName" />
                  <van-cell title-class="cell-title" value-class="cell-value truncate" :border="false" title="Order ID" v-if="resultInfo.txnRefId" :value="resultInfo.txnRefId" />
                  <van-cell title-class="cell-title" value-class="cell-value text-ellipsis2" :border="false" title="Product Name" v-if="resultInfo.productName" :value="resultInfo.productName" />
                  <van-cell title-class="cell-title" value-class="cell-value text-ellipsis3" :border="false" title="Remark" v-if="resultInfo.remark" :value="resultInfo.remark" />
                </van-cell-group>
              </div>
            </template>
            <div class="text-center flex items-center justify-center">
              <div class="align-middle xl:text-lg <xl:text-[18px] footer-text">Secured by</div>
              <img class="align-middle xl:w-28.5 xl:h-6.5 xl:text-lg <xl:w-[114px] <xl:h-[26px] <xl:text-[18px]" src="../assets/logo_colour_mpurse.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class=" w-full flex flex-row justify-around items-center xl:w-120 xl:mt-4 <xl:max-w-[480px] <xl:mt-[30px]">
        <button class="xl:w-55 xl:h-15 xl:text-xl <xl:w-[190px] <xl:h-[60px] <xl:text-[18px] rounded-3xl bg-$color-fff8 border-$theme-color text-$theme-color border" @click="close">Cancel</button>
        <button class="xl:w-55 xl:h-15 xl:text-xl <xl:w-[190px] <xl:h-[60px] <xl:text-[18px] rounded-3xl bg-$theme-color border-$theme-color text-$color-fff border" @click="save">Save</button>
      </div>
    </div>
</template>

<script setup>
import {useStore} from "vuex";
import { ref, nextTick } from "vue";
import qrcode from "qrcode";
import html2canvas from "html2canvas";

const iconSuccess = require('../assets/icon_success.png');
const iconFail = require('../assets/icon_fail.png');
const iconPending = require('../assets/icon_pending.png');

const homeImg = require('../assets/icon_home.png');
const store = useStore();
const props = defineProps({
    qrUrl: {
      type: String,
      default: ''
    },
    resultInfo:{
      typeof: Object,
      default: ()=>{
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    },
})
const emit = defineEmits(['saveMask']);
const close = ()=>{
  emit('saveMask', false)
}
const canvasUrl = ref('')
const getCanvasUrl = ()=>{
    let node = document.getElementById("mycanvas"); //传入的id名称
    let width = node.offsetWidth; //dom宽
    let height = node.offsetHeight; //dom高
    html2canvas(node, {
       width: width,
       heigth: height,
       backgroundColor: null, //背景颜色 为null是透明
       dpi: window.devicePixelRatio * 2, //按屏幕像素比增加像素
       useCORS: true, //是否使用CORS从服务器加载图像
       allowTaint: false,
    }).then((canvas) => {
      canvasUrl.value = canvas.toDataURL(); //这里上面不设值cors会报错
    });
}
nextTick(() => {
  if(props.type === 'qr'){
    let qrCanvas = document.getElementById("qrCanvas");
    qrcode.toCanvas(qrCanvas, props.qrUrl, {
      errorCorrectionLevel: "H",
      height: 180,
      width: 180,
    });
  }
  getCanvasUrl();
});
const save = ()=>{
  let a = document.createElement("a"); //创建一个a标签 用来下载
  a.style.display = 'none';
  a.href = canvasUrl.value;//此处的url为base64格式的图片资源
  a.download = 'mpurse_qrCode.png'; //设置下载的图片名称
  let event = new MouseEvent("click"); //增加一个点击事件
  //如果需要下载的话就加上这两句
  a.dispatchEvent(event); //触发a的单击事件 即可完成下载
}
</script>
<style lang="scss" type="text/scss">
.mask
  .van-cell-group{
    .van-cell{
      padding: 0;
      line-height: 16px;
      font-size: 14px;
      margin-bottom: 20px;
      .cell-title{
        text-align: left;
        font-weight: 400;
        color: var(--black-color)
      }
      .cell-value{
        text-align: right;
        flex: 1;
        font-weight: 300;
        color: var(--black-color)
      }
    }
  }
</style>